/* eslint-disable */
import React from "react";
import Button from "react-bootstrap/Button";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import queryString from 'query-string';
import { Link } from "gatsby";
import '../forgot-my-password/style.scss'
import axios from 'axios'
import {getPasswordResetBaseURL} from '../../utils/getPasswordResetBaseURL';

export default class extends React.Component {
  state = {
    passwordResetMessage: '',
    validationMessage: '',
    password: '',
    confirmPassword: ''
  };

  constructor(props) {
    super(props);

  }


  resetPasswordClicked = () => {
    const {password, confirmPassword} = this.state;
    if (!password || !confirmPassword) {
      this.setState({
        validationMessage: 'You must enter your password in both fields'
      })
    } else if (password !== confirmPassword) {
      this.setState({
        validationMessage: 'Your passwords do not match'
      })
    } else if (password.length < 6) {
      this.setState({
        validationMessage: 'Your password must be at least 6 characters long'
      })
    } else {
      const {code: resetCode} = queryString.parse(location.search);

      axios({
        method: 'post',
        url: `${getPasswordResetBaseURL}/reset-account-password`,
        data: {
          resetCode,
          password
        }
      }).then(response => {
        if(response.data.errorMessage){
          this.setState({
            validationMessage: response.data.errorMessage
          })
        }else{
          this.setState({
            passwordResetMessage: response.data.message
          })
        }
      }).catch(error => {
        this.setState({
          passwordResetMessage: error.response.data.errorMessage
        })
      })
    }
  }

render() {
  const {
    passwordResetMessage,
    validationMessage,
    password,
    confirmPassword
  } = this.state;
  return (
    <Layout>
      <SEO title="Reset Password" />
      <Container fluid className="join-page reset-password">
        <Row>
          <Col className="left-col">
            <Col className="text-center">
              <div className="form-box">
                {passwordResetMessage && (
                    <div className="password-success-reset-message">
                      <p>{passwordResetMessage}
                      </p>
                      <Link to="/join" className="button is-primary is-black">
                        Login
                      </Link>
                    </div>
                )}
                {!passwordResetMessage && (
                <div className="Login">
                  <h2>Reset Password</h2>
                  <p>
                    Please enter your email below to recover your account using
                    an email code.
                  </p>
                  {validationMessage && (
                      <div className='validation-message'>
                        {validationMessage}
                      </div>
                  )}
                  <form className="form-signin mt-4">
                    <div className="form-label-group">
                      <input
                        autoFocus
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="New Password"
                        required
                        value={password}
                        onChange={({target}) => {
                          this.setState({
                            validationMessage: '',
                            password: target.value
                          })
                        }}
                      />
                      <input
                        autoFocus
                        type="password"
                        id="confirm-password"
                        className="form-control"
                        placeholder="Confirm New Password"
                        required
                        value={confirmPassword}
                        onChange={({target}) => {
                          this.setState({
                            validationMessage: '',
                            confirmPassword: target.value
                          })
                        }}
                      />
                    </div>
                    <Button block variant="secondary" onClick={this.resetPasswordClicked}>Reset Password</Button>
                  </form>
                </div>
                )}
              </div>
            </Col>
          </Col>
          {/* <Col className="right-col"></Col> */}
        </Row>
      </Container>
    </Layout>
  )
}
}
