/* eslint-disable */
// const getGatsbyEnv = require('./../utils/getGatsbyEnv')

// export default () => {
//   if (getGatsbyEnv() === 'production') {
//     return 'https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod'
//   }
//   return 'https://jhhs6d8jmb.execute-api.eu-west-1.amazonaws.com/dev'
// }

export const getPasswordResetBaseURL = 'https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod';
